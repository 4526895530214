import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9c9ddf8a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\r\n        overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 2
};
const _hoisted_6 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-direction": "column"
  }
};
const _hoisted_8 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_9 = {
  key: 4,
  style: {
    "width": "100%"
  }
};
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = {
  key: 4,
  style: {
    "text-align": "center"
  }
};
import { ref, reactive, onMounted } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_lanxiaozi',
  setup(__props) {
    const {
      readExcelFromRemoteFile,
      fetchTextFile,
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let showFile = ref(false);
    let currentRow = reactive({
      obj: {}
    });
    let currentRow1 = reactive({
      obj: {}
    });
    let termDescList = reactive({
      obj: [{}]
    });
    let srcList = reactive({
      obj: []
    });
    let currentFile = reactive({
      obj: {}
    });
    let activeName = ref("字根管理");
    let fileList = reactive({
      obj: []
    });
    let reqData = reactive({
      obj: {
        url: "/docLanxiaozi/getAll",
        data: {
          name: "",
          agent_id: ""
        }
      }
    });
    let vHtml = ref();
    let currentTxt = ref();
    let columns0 = reactive({
      obj: [{
        name: "character_term",
        title: "期"
      }, {
        name: "character_term_price",
        title: "期价格"
      }, {
        name: "character_term_desc",
        title: "期详情"
      }, {
        name: "character_roots",
        title: "字根详情"
      }]
    });
    let columns = [{
      name: "character_term",
      title: "期"
    }, {
      name: "character_term_price",
      title: "期价格"
    }, {
      name: "character_term_desc",
      title: "期详情"
    }, {
      name: "character_roots",
      title: "字根详情"
    }

    // {
    //   name: "roots",
    //   title: "字根 ",
    // },
    // {
    //   name: "characters",
    //   title: "字 ",
    // },
    ];
    let columns1 = [{
      name: "character_term",
      title: "期名"
    }, {
      name: "character_term_price",
      title: "期价格",
      type: "Number"
    }, {
      name: "character_term_desc",
      title: "详细描述 ",
      type: "Array"
    }];

    /**
     *     character_root: String, //字根
        character_desc: String, //介绍
        character_term_id: String, //期
        create_dtme: String,
     */
    let columns2 = [{
      name: "character_root",
      title: "字根名称"
    }, {
      name: "character_desc",
      title: "字根描述",
      type: "Number"
    }];
    let columns3 = [{
      name: "account_name",
      title: "账户名"
    }, {
      name: "password",
      title: "密码"
    }, {
      name: "create_dtme",
      title: "注册时间 "
    }];
    let fieldsShow = ref(false);
    let selectedFields = reactive({
      obj: []
    });
    let exportType = ref(1);
    let imgList = reactive({
      obj: []
    });
    let writerList = reactive({
      obj: []
    });
    let users = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    let smsWayShow1 = ref(false);
    onMounted(() => {
      reqData.obj.data.agent_id = account._id;
      currentRow.obj.agent_id = account._id;
      doGetData();
    });
    function newDesc() {
      termDescList.obj.push({});
    }
    function removeDesc(index, list) {
      list.splice(index, 1);
    }
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      smsWayShow.value = true;
    }
    function changeTab(val) {
      console.log(val.props.name);
      if (val.props.name == "用户管理") {
        reqData.obj.url = "/docLanxiaozi/getUsers";
        columns0.obj = columns3;
      } else if (val.props.name == "字根管理") {
        reqData.obj.url = "/docLanxiaozi/getAll";
        columns0.obj = columns1;
      }
      doGetData();
    }
    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, "/docTool/deleteMessage/" + id));
      doGetData();
    }
    async function addSmsWay() {
      let character_term_desc = [];
      for (const element of termDescList.obj) {
        character_term_desc.push({
          content: element.content
        });
      }
      currentRow.obj.character_term_desc = character_term_desc;
      const res = await withLoading(apiAll(currentRow.obj, "/docLanxiaozi/addTerm"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    function showAddRoot(row) {
      currentRow1.obj.character_term_id = row._id;
      smsWayShow1.value = true;
    }
    async function addRoot() {
      const res = await withLoading(apiAll(currentRow1.obj, "/docLanxiaozi/addRoot"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow1.value = false;
        doGetData();
      }
    }
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_Plus = _resolveComponent("Plus");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_VueOfficeDocx = _resolveComponent("VueOfficeDocx");
      const _component_VueOfficeExcel = _resolveComponent("VueOfficeExcel");
      const _component_VueOfficePdf = _resolveComponent("VueOfficePdf");
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          style: {
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tabs, {
            modelValue: _unref(activeName),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(activeName) ? activeName.value = $event : activeName = $event),
            class: "demo-tabs",
            onTabClick: changeTab
          }, {
            default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
              label: "字根管理",
              name: "字根管理"
            }), _createVNode(_component_el_tab_pane, {
              label: "用户管理",
              name: "用户管理"
            }), _createVNode(_component_el_tab_pane, {
              label: "订单管理",
              name: "订单管理"
            }), _createVNode(_component_el_tab_pane, {
              label: "网站设置",
              name: "网站设置"
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_unref(activeName) == '字根管理' ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[1] || (_cache[1] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode(" 新增 ")]),
            _: 1
          }, 8, ["size"])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: true,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns0).obj, (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": "180px",
            "column-key": index,
            width: "auto",
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [i.name == 'character_term_desc' ? (_openBlock(), _createElementBlock("span", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row[i.name], (i, index) => {
              return _openBlock(), _createBlock(_component_el_tag, {
                style: {
                  "margin-right": "10px"
                },
                key: index
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(i.content), 1)]),
                _: 2
              }, 1024);
            }), 128))])) : i.name == 'character_roots' ? (_openBlock(), _createElementBlock("span", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row[i.name], (i, index) => {
              return _openBlock(), _createBlock(_component_el_tag, {
                style: {
                  "margin-right": "10px"
                },
                key: index
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(i.character_root), 1)]),
                _: 2
              }, 1024);
            }), 128))])) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(scope.row[i.name]), 1))]),
            _: 2
          }, 1032, ["look", "column-key", "label"]);
        }), 128)), _unref(account).account_auth == 'admin' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          width: "230",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showAddRoot(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 添加字根 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 修改信息 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode(" 删除 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[2] || (_cache[2] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[3] || (_cache[3] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "导出数据",
        left: "",
        modelValue: _unref(fieldsShow),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(fieldsShow) ? fieldsShow.value = $event : fieldsShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("字段选择")]),
          _: 1
        }), _createVNode(_component_el_checkbox_group, {
          modelValue: _unref(selectedFields).obj,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(selectedFields).obj = $event),
          style: {
            "margin-bottom": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: i,
              key: index
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(i.title), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("导出类型")]),
          _: 1
        }), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_radio_group, {
          modelValue: _unref(exportType),
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(exportType) ? exportType.value = $event : exportType = $event),
          size: _ctx.size
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: 1
          }, {
            default: _withCtx(() => [_createTextVNode("导出当前")]),
            _: 1
          }), _createVNode(_component_el_radio_button, {
            label: 0
          }, {
            default: _withCtx(() => [_createTextVNode("导出所有")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "size"])]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: _ctx.exportUser
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定导出")]),
          _: 1
        }, 8, ["size", "onClick"])])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "字根",
        modelValue: _unref(smsWayShow1),
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _isRef(smsWayShow1) ? smsWayShow1.value = $event : smsWayShow1 = $event),
        width: 500
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns2), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'number' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "number",
                modelValue: _unref(currentRow1).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow1).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                type: "textarea",
                rows: 5,
                modelValue: _unref(currentRow1).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow1).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 2,
                modelValue: _unref(currentRow1).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow1).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.options, (j, index) => {
                  return _openBlock(), _createBlock(_component_el_radio, {
                    label: j,
                    value: j,
                    key: index
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 3,
                modelValue: _unref(currentRow1).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow1).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["label-position"]), _createVNode(_component_el_form_item, {
          "label-width": "130px",
          style: {
            "display": "flex",
            "justify-content": "center"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            size: _ctx.size,
            icon: "select",
            onClick: addRoot
          }, {
            default: _withCtx(() => [_createTextVNode(" 提交")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
        title: "期",
        top: "2.5vh",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: 1000
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "height": "700px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns1), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'number' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "number",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                type: "textarea",
                rows: 5,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 2,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.options, (j, index) => {
                  return _openBlock(), _createBlock(_component_el_radio, {
                    label: j,
                    value: j,
                    key: index
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : i.type == 'file' ? (_openBlock(), _createBlock(_component_el_upload, {
                key: 3,
                style: {
                  "display": "inline-block"
                },
                action: "#",
                limit: "1",
                "auto-upload": false,
                "on-change": _ctx.handleChange,
                "file-list": _unref(fileList).obj,
                "onUpdate:fileList": _cache[8] || (_cache[8] = $event => _unref(fileList).obj = $event)
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon, {
                  class: "avatar-uploader-icon"
                }, {
                  default: _withCtx(() => [_createVNode(_component_Plus), _createTextVNode("图片")]),
                  _: 1
                })]),
                _: 1
              }, 8, ["on-change", "file-list"])) : i.type == 'Array' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_el_button, {
                onClick: newDesc,
                icon: "plus",
                text: ""
              }, {
                default: _withCtx(() => [_createTextVNode("新增描述")]),
                _: 1
              }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(termDescList).obj, (i, index) => {
                return _openBlock(), _createElementBlock("div", {
                  key: index,
                  style: {
                    "width": "100%"
                  }
                }, [_createVNode(_component_el_input, {
                  style: {
                    "width": "100%"
                  },
                  type: "textarea",
                  rows: 5,
                  placeholder: "输入更多关于这一期视频的描述，吸引更多用户",
                  modelValue: i.content,
                  "onUpdate:modelValue": $event => i.content = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_el_button, {
                  onClick: $event => removeDesc(index, _unref(termDescList).obj),
                  icon: "delete",
                  text: ""
                }, {
                  default: _withCtx(() => [_createTextVNode("移除")]),
                  _: 2
                }, 1032, ["onClick"]), _createVNode(_component_el_upload, {
                  style: {
                    "display": "inline-block"
                  },
                  action: "#",
                  limit: "1",
                  "auto-upload": false,
                  "on-change": _ctx.handleChange,
                  "file-list": i.fileList,
                  "onUpdate:fileList": $event => i.fileList = $event
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_icon, {
                    class: "avatar-uploader-icon"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_Plus)]),
                    _: 1
                  })]),
                  _: 2
                }, 1032, ["on-change", "file-list", "onUpdate:fileList"])]);
              }), 128))])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 5,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["label-position"]), _createVNode(_component_el_form_item, {
          "label-width": "130px",
          style: {
            "display": "flex",
            "justify-content": "center"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            size: _ctx.size,
            icon: "select",
            onClick: addSmsWay
          }, {
            default: _withCtx(() => [_createTextVNode(" 提交")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
        modelValue: _unref(showFile),
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(showFile) ? showFile.value = $event : showFile = $event),
        fullscreen: true,
        width: _unref(getDialogWdith),
        center: ""
      }, {
        default: _withCtx(() => [_unref(currentFile).obj.expandName == 'docx' ? (_openBlock(), _createBlock(_component_VueOfficeDocx, {
          key: 0,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'doc' ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: "wordView",
          innerHTML: _unref(vHtml)
        }, null, 8, _hoisted_10)) : _unref(currentFile).obj.expandName == 'xlx' || _unref(currentFile).obj.expandName == 'xlsx' ? (_openBlock(), _createBlock(_component_VueOfficeExcel, {
          key: 2,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'pdf' ? (_openBlock(), _createBlock(_component_VueOfficePdf, {
          key: 3,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_withDirectives(_createElementVNode("textarea", {
          style: {
            "border": "none"
          },
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(currentTxt) ? currentTxt.value = $event : currentTxt = $event),
          readonly: "",
          rows: "20",
          cols: "80"
        }, null, 512), [[_vModelText, _unref(currentTxt)]])]))]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_image, {
        id: "show-image",
        style: {
          "width": "0",
          "height": "0"
        },
        src: _unref(currentFile).obj.url,
        "zoom-rate": 1.2,
        "max-scale": 7,
        "min-scale": 0.2,
        "preview-src-list": _unref(srcList).obj,
        "initial-index": _unref(srcList).obj.length - 1,
        fit: "cover"
      }, null, 8, ["src", "preview-src-list", "initial-index"])])]);
    };
  }
};