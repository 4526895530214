import "core-js/modules/es.array.push.js";
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-799a5ef9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\r\n        overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  style: {
    "font-size": "15px",
    "font-weight": "bold",
    "padding": "20px"
  }
};
const _hoisted_5 = {
  style: {
    "font-size": "12px",
    "color": "#c8c9cc"
  }
};
const _hoisted_6 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-direction": "column"
  }
};
const _hoisted_8 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = {
  key: 4,
  style: {
    "text-align": "center"
  }
};
import { ref, reactive, onMounted } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_message',
  setup(__props) {
    const {
      readExcelFromRemoteFile,
      fetchTextFile,
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let showFile = ref(false);
    let currentRow = reactive({
      obj: {
        content_type: "通知"
      }
    });
    let srcList = reactive({
      obj: []
    });
    let currentFile = reactive({
      obj: {}
    });
    let fileList = reactive({
      obj: []
    });
    let reqData = reactive({
      obj: {
        url: "/docTool/getShortMessage",
        data: {
          name: "",
          agent_id: ""
        }
      }
    });
    let access_file = reactive({
      obj: {}
    });
    let vHtml = ref();
    let currentTxt = ref();
    let columns = reactive([{
      name: "content_type",
      title: "内容类别"
    }, {
      name: "content",
      title: "站内信内容 "
    }, {
      name: "to_writer_id",
      title: "接收人 "
    }, {
      name: "is_read",
      title: "是否已读"
    }, {
      name: "create_dtme",
      title: "发送时间"
    }]);
    let columns1 = reactive([{
      name: "content_type",
      title: "内容类别",
      type: "radio",
      options: ["通知", "重要", "其他"]
    }, {
      name: "content",
      title: "站内信内容 ",
      type: "textarea"
    }, {
      name: "to_writer_id",
      title: "接收人 "
    }]);
    let fieldsShow = ref(false);
    let selectedFields = reactive({
      obj: []
    });
    let exportType = ref(1);
    let imgList = reactive({
      obj: []
    });
    let writerList = reactive({
      obj: []
    });
    let users = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    onMounted(() => {
      reqData.obj.data.agent_id = account._id;
      currentRow.obj.agent_id = account._id;
      doGetData();
      getAllWriter();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      smsWayShow.value = true;
    }
    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, "/docTool/deleteMessage/" + id));
      doGetData();
    }
    async function readMessage(id) {
      let data = {};
      const res = await withLoading(apiAll(data, "/docTool/readMessage/" + id));
      doGetData();
    }
    async function getAllWriter() {
      let data = {};
      const res = await withLoading(apiAll(data, "/docWriter/getAllWriter"));
      writerList.obj = res;
    }
    async function addSmsWay() {
      const res = await withLoading(apiAll(currentRow.obj, "/docTool/addShortMessage"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_Upload = _resolveComponent("Upload");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_Plus = _resolveComponent("Plus");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_VueOfficeDocx = _resolveComponent("VueOfficeDocx");
      const _component_VueOfficeExcel = _resolveComponent("VueOfficeExcel");
      const _component_VueOfficePdf = _resolveComponent("VueOfficePdf");
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_unref(account).account_auth == 'admin' ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[0] || (_cache[0] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode(" 新增消息 ")]),
            _: 1
          }, 8, ["size"])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList).obj, (i, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          style: {
            "display": "flex",
            "justify-content": "space-between",
            "min-height": "60px",
            "border-bottom": "1px solid #c8c9cc",
            "padding": "10px"
          }
        }, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_4, [i.is_top == 1 ? (_openBlock(), _createBlock(_component_el_tag, {
          key: 0,
          type: "success",
          style: {
            "margin-right": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Upload)]),
            _: 1
          }), _createTextVNode("置顶")]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_tag, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(i.content_type), 1)]),
          _: 2
        }, 1024), _createTextVNode(": " + _toDisplayString(i.content), 1)]), _createElementVNode("div", _hoisted_5, _toDisplayString(i.create_dtme), 1)]), _createElementVNode("div", null, [_unref(account).account_auth == 'admin' ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          size: "small",
          type: "primary",
          text: "",
          link: "",
          icon: "Upload",
          onClick: $event => _ctx.topShortMessage(i._id)
        }, {
          default: _withCtx(() => [_createTextVNode(" 置顶 ")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true), _unref(account).account_auth == 'user' && i.to_writer_id && i.to_writer_id == _unref(account)._id ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          size: "small",
          type: "primary",
          text: "",
          link: "",
          icon: "view",
          onClick: $event => readMessage(i._id)
        }, {
          default: _withCtx(() => [_createTextVNode(" 阅读 ")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true), _unref(account).account_auth == 'admin' ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          size: "small",
          type: "danger",
          text: "",
          link: "",
          icon: "delete",
          onClick: $event => deleteById(i._id)
        }, {
          default: _withCtx(() => [_createTextVNode(" 删除 ")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
      }), 128))])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[1] || (_cache[1] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[2] || (_cache[2] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "导出数据",
        left: "",
        modelValue: _unref(fieldsShow),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(fieldsShow) ? fieldsShow.value = $event : fieldsShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("字段选择")]),
          _: 1
        }), _createVNode(_component_el_checkbox_group, {
          modelValue: _unref(selectedFields).obj,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(selectedFields).obj = $event),
          style: {
            "margin-bottom": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: i,
              key: index
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(i.title), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("导出类型")]),
          _: 1
        }), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_radio_group, {
          modelValue: _unref(exportType),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(exportType) ? exportType.value = $event : exportType = $event),
          size: _ctx.size
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: 1
          }, {
            default: _withCtx(() => [_createTextVNode("导出当前")]),
            _: 1
          }), _createVNode(_component_el_radio_button, {
            label: 0
          }, {
            default: _withCtx(() => [_createTextVNode("导出所有")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "size"])]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: _ctx.exportUser
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定导出")]),
          _: 1
        }, 8, ["size", "onClick"])])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "站内信",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns1), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'number' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "number",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'password' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                type: "password",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 2,
                type: "textarea",
                rows: 5,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'sex' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 3,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio, {
                  label: "男",
                  value: "男"
                }), _createVNode(_component_el_radio, {
                  label: "女",
                  value: "女"
                })]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])) : i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 4,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.options, (j, index) => {
                  return _openBlock(), _createBlock(_component_el_radio, {
                    label: j,
                    value: j,
                    key: index
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : i.type == 'file' ? (_openBlock(), _createBlock(_component_el_upload, {
                key: 5,
                style: {
                  "display": "inline-block"
                },
                action: "#",
                limit: "1",
                "auto-upload": false,
                "on-change": _ctx.handleChange,
                "file-list": _unref(fileList).obj,
                "onUpdate:fileList": _cache[6] || (_cache[6] = $event => _unref(fileList).obj = $event)
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon, {
                  class: "avatar-uploader-icon"
                }, {
                  default: _withCtx(() => [_createVNode(_component_Plus)]),
                  _: 1
                })]),
                _: 1
              }, 8, ["on-change", "file-list"])) : i.name == 'to_writer_id' ? (_openBlock(), _createBlock(_component_el_select, {
                key: 6,
                modelValue: _unref(currentRow).obj.to_writer_id,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(currentRow).obj.to_writer_id = $event),
                placeholder: "接收人",
                size: "large",
                style: {
                  "width": "240px"
                }
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(writerList).obj, item => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: item._id,
                    label: item.writer_name,
                    value: item._id
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 7,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128)), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode(" 提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        modelValue: _unref(showFile),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(showFile) ? showFile.value = $event : showFile = $event),
        fullscreen: true,
        width: _unref(getDialogWdith),
        center: ""
      }, {
        default: _withCtx(() => [_unref(currentFile).obj.expandName == 'docx' ? (_openBlock(), _createBlock(_component_VueOfficeDocx, {
          key: 0,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'doc' ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: "wordView",
          innerHTML: _unref(vHtml)
        }, null, 8, _hoisted_9)) : _unref(currentFile).obj.expandName == 'xlx' || _unref(currentFile).obj.expandName == 'xlsx' ? (_openBlock(), _createBlock(_component_VueOfficeExcel, {
          key: 2,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'pdf' ? (_openBlock(), _createBlock(_component_VueOfficePdf, {
          key: 3,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_withDirectives(_createElementVNode("textarea", {
          style: {
            "border": "none"
          },
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(currentTxt) ? currentTxt.value = $event : currentTxt = $event),
          readonly: "",
          rows: "20",
          cols: "80"
        }, null, 512), [[_vModelText, _unref(currentTxt)]])]))]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_image, {
        id: "show-image",
        style: {
          "width": "0",
          "height": "0"
        },
        src: _unref(currentFile).obj.url,
        "zoom-rate": 1.2,
        "max-scale": 7,
        "min-scale": 0.2,
        "preview-src-list": _unref(srcList).obj,
        "initial-index": _unref(srcList).obj.length - 1,
        fit: "cover"
      }, null, 8, ["src", "preview-src-list", "initial-index"])])]);
    };
  }
};