import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      mainHeight: "",
      show: true,
      model: {
        account_name: "",
        password: ""
      },
      showError1: false,
      showError2: false,
      showError3: false,
      mobileError: "",
      focusPass: false,
      focusUserName: false,
      focusmobile: false,
      isRegist: false
    };
  },
  created() {
    this.getInit();
  },
  setup() {},
  methods: {
    getInit() {
      this.mainHeight = window.innerHeight + "px";
    },
    checkPass() {
      this.focusPass = false;
      this.showError2 = !this.model.password;
    },
    checkUserName() {
      this.focusUserName = false;
      this.showError1 = !this.model.account_name;
    },
    checkMobile() {
      //手机号正则
      if (this.model.mobile) {
        let regexp = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
        var reg = new RegExp(regexp);
        if (!reg.test(this.model.mobile)) {
          this.mobileError = "手机号格式不正确！";
        }
      }
      this.focusmobile = false;
      this.showError3 = !this.model.mobile;
    },
    /**
     * 用户注册
     * 信息不存在或手机号存在问题时返回
     */
    async doRegist() {
      if (!this.model.account_name || !this.model.password || !this.model.mobile || this.mobileError) {
        return false;
      }
      this.model.user_name = this.model.mobile;
      let data = {};
      data.params = JSON.stringify(this.model);
      const res = await this.withLoading(this.apiAll(data, "/createAccountSelf"));
      this.disableCreate = false;
      if (res.code == 0) {
        this.successMsgBox("注册成功！");
        //直接登录
        this.doLogin();
        this.isRegist = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async doLogin() {
      if (!this.model.account_name || !this.model.password) {
        return false;
      }
      let data = {};
      data.params = JSON.stringify(this.model);
      const loginMes = await this.withLoading(this.apiAll(data, "/loginAccount"));
      if (loginMes.code == 0) {
        localStorage.setItem("offical_accountInfo", JSON.stringify(loginMes.extra.accountInfo));
        localStorage.setItem("offical_accessToken", loginMes.extra.accessToken);

        //默认跳到活动页面
        let tab = {};
        tab.title = "首页";
        tab.name = "index";
        let editableTabs = [];
        editableTabs.push(tab);
        window.sessionStorage.setItem("offical_events_tabs", JSON.stringify(editableTabs));
        window.sessionStorage.setItem("offical_events_currentTab", "index");
        this.$router.push("index");
      } else {
        this.errorMsgBox(loginMes.message);
      }
    }
  }
};