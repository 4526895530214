import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100%",
    "margin": "0 auto",
    "display": "flex",
    "justify-content": "center"
  }
};
import { ref, reactive, onMounted } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_writer_info',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    return (_ctx, _cache) => {
      const _component_a_card = _resolveComponent("a-card");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_card, {
        title: "我的信息",
        bordered: false,
        style: {
          "width": "700px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("p", null, "姓名：" + _toDisplayString(_unref(account).writer_name), 1), _createElementVNode("p", null, "工龄：" + _toDisplayString(_unref(account).work_age), 1), _createElementVNode("p", null, "工作：" + _toDisplayString(_unref(account).writer_job), 1), _createElementVNode("p", null, "个人备注：" + _toDisplayString(_unref(account).writer_info), 1)]),
        _: 1
      })]);
    };
  }
};